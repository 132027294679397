import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const allowedEnvs = ["production", "staging"];

type ConfigureProps = {
  applicationId: string;
  clientToken: string;
  version: string;
  service: string;
};
type ConfigureDataDog = (props: ConfigureProps) => void;

const configureDataDogRum: ConfigureDataDog = ({
  applicationId,
  clientToken,
  version,
  service,
}) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.com",
    service,
    version,
    sessionSampleRate: 50,
    sessionReplaySampleRate: 10,
    traceSampleRate: 100,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      { match: "https://api.creditas.io", propagatorTypes: ["datadog"] },
      { match: /https:\/\/.*\.creditas\.io/, propagatorTypes: ["datadog"] },
    ],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ["clickmap"],
  });

  datadogRum.startSessionReplayRecording();
};

const configureDataDogLogs: ConfigureDataDog = ({
  clientToken,
  version,
  service,
}) => {
  datadogLogs.init({
    clientToken,
    version,
    service,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    forwardReports: "all",
    sessionSampleRate: 50,
  });

  datadogLogs.logger.setContextProperty("host", window.location.host);
};

function startMonitoring() {
  const appEnv = process.env.APP_ENV;
  const applicationId = process.env.DATADOG_APPLICATION_ID;
  const clientToken = process.env.DATADOG_CLIENT_TOKEN;
  const version = "finops-loan-management-portal";
  const service = process.env.DATADOG_SERVICE;

  const config = {
    applicationId,
    clientToken,
    version,
    service,
  };

  if (allowedEnvs.includes(appEnv)) {
    configureDataDogLogs(config);
    configureDataDogRum(config);
  }
}

export { startMonitoring };
